<template>
  <div>
    <GeneralDashboard
      @notify-parent="searchComplaints"
      ref="my_filter_search"
    />
    <div
      class="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 3xl:grid-cols-8 md:gap-x-5 gap-1 justify-around"
    >
      <ComplaintCardMini
        v-for="complaint in complaints"
        :key="complaint.title"
        :title="complaint.title"
        :numComplaints="complaint.numComplaints"
        :percent="complaint.percent"
        :danger="complaint.danger"
        :fall="complaint.fall"
      />
    </div>
    <div
      v-if="!loading"
      class="relative sm:py-8 sm:px-16 sm:bg-white sm:mx-auto sm:h-full rounded-xl shadow-xl py-5 px-5 border border-gray-300 search-complaint"
    >
      <div class="flex justify-between align-center">
        <h3 class="font-bold sm:text-xl text-base text-left">
          Información de las Quejas
        </h3>

        <a-menu mode="horizontal">
          <a-sub-menu>
            <a-button
              slot="title"
              type="primary"
              style="margin-bottom: 16px"
              @click="collapsed = !collapsed"
            >
              <a-icon :type="collapsed ? 'menu-unfold' : 'menu-fold'" />
            </a-button>
            <a-menu-item
              ><vue-excel-xlsx
                :loading="loadingReport"
                :dataSheets="[complaintsInfo, filtersToDownload.data]"
                :sheetNames="['Reporte', 'Filtros']"
                book
                :transpose="[false, true]"
                :columnsSheets="[columnsExcel, filtersToDownload.columns]"
                file-name="Búsqueda avanzada"
              >
                <span class="mx-2">Descargar vista actual XLSX</span>
              </vue-excel-xlsx></a-menu-item
            >
            <a-menu-item>
              <vue-excel-xlsx
                :async="true"
                ref="excel-complete"
                id="complete"
                :loading="loadingReport"
                :dataSheets="[json_data, filtersToDownload.data]"
                :columnsSheets="[columnsExcel, filtersToDownload.columns]"
                file-name="Búsqueda avanzada(Completo)"
                :sheetNames="['Reporte', 'Filtros']"
                book
                :transpose="[false, true]"
              >
                <span class="mx-2" @click="onGetAllComplaints(false)"
                  >Descargar reporte completo XLSX</span
                >
              </vue-excel-xlsx>
            </a-menu-item>
            <a-menu-item>
              <vue-excel-xlsx
                :async="true"
                ref="excel-complete-csv"
                id="complete-csv"
                :loading="loadingReport"
                :dataSheets="[json_csv, filtersToDownload.data]"
                :columnsSheets="[columnsExcel, filtersToDownload.columns]"
                file-type="csv"
                file-name="Búsqueda avanzada(Completo)"
                :sheetNames="['Reporte', 'Filtros']"
                book
                :transpose="[false, true]"
              >
                <span class="mx-2" @click="onGetAllComplaints(true)"
                  >Descargar reporte completo CSV</span
                >
              </vue-excel-xlsx></a-menu-item
            >
          </a-sub-menu>
        </a-menu>
      </div>
      <a-table
        :columns="columns"
        :data-source="complaintsInfo"
        :pagination="false"
        rowKey="codigo_queja"
        :scroll="{ x: '1600px' }"
        class="sm:mt-5 border rounded-xl p-1"
      >
        <div slot="numero_id_CF" slot-scope="text, record" class="text-center">
          {{ record.numero_id_CF }}
        </div>
        <div
          slot="queja_expres"
          slot-scope="text, record"
          class="text-center font-bold"
        >
          {{ record.queja_expres }}
        </div>
        <div slot="estado" slot-scope="text, record" class="text-center">
          <ComplaintStatusLabel :status="record.estado" danger="true" />
        </div>
        <div
          slot="fecha_creacion"
          slot-scope="text, record"
          class="text-center"
        >
          {{ record.fecha_creacion }}
        </div>
      </a-table>
      <div class="mt-4 flex justify-end">
        <a-pagination
          v-model="page"
          :page-size="pageSize"
          :total="count"
          show-less-items
          size="large"
        />
      </div>
    </div>
    <div v-if="loading" class="absolute w-full justify-center top-1/2 left-0">
      <a-spin>
        <a-icon slot="indicator" type="loading" style="font-size: 64px" spin />
      </a-spin>
    </div>
  </div>
</template>

<script>
import GeneralDashboard from "@/components/Dashboard/Management/GeneralDashboard";
import ComplaintCardMini from "@/components/Dashboard/Management/General/ComplaintCardMini";
import { format } from "date-fns";
import moment from "moment";
import capitalize from "@/utils/checkForms.js";
import ComplaintStatusLabel from "@/components/Dashboard/Complaints/ComplaintStatusLabel.vue";

const arrVolumetry = [
  {
    name: "Total de quejas",
    target: "total_codigo_queja",
    percent: "porcentaje_codigo_queja",
  },
  {
    name: "Quejas con Tutela",
    target: "total_tutela",
    percent: "porcentaje_tutela",
  },
  {
    name: "Total Entidades",
    target: "total_nombre_entidad",
    percent: "porcentaje_nombre_entidad",
  },
  {
    name: "Total Usuarios",
    target: "total_numero_id_CF",
    percent: "porcentaje_numero_id_CF",
  },
  {
    name: "Total Ciudades",
    target: "total_codigo_pais",
    percent: "porcentaje_codigo_pais",
  },
  {
    name: "Tiempo total de respuesta",
    target: "sum_secconds",
    percent: "porcentaje_sum_secconds",
  },
  {
    name: "Cantidad de prorrogas",
    target: "total_sum_prorroga_queja",
    percent: "porcentaje_prorroga_queja",
  },
  {
    name: "Tiempo promedio de respuesta",
    target: "avg_secconds",
    percent: "porcentaje_avg_secconds",
  },
];

const columnsBackup = [
  {
    title: "Código de la Queja",
    dataIndex: "codigo_queja",
    key: "codigo_queja",
    width: 100,
  },
  {
    title: "Tipo de Identificación  ",
    dataIndex: "tipo_identificacion_CF",
    key: "tipo_identificacion_CF",
    width: 100,
  },
  {
    title: "Número de Identifiación",
    dataIndex: "numero_id_CF",
    key: "numero_id_CF",
    width: 100,
    scopedSlots: { customRender: "numero_id_CF" },
  },
  { title: "Nombre", dataIndex: "nombres", key: "nombres", width: 100 },
  {
    title: "Entidad",
    dataIndex: "nombre_entidad",
    key: "nombre_entidad",
    width: 175,
  },
  {
    title: "Producto",
    dataIndex: "producto_nombre",
    key: "producto_nombre",
    width: 100,
  },
  {
    title: "Express",
    dataIndex: "queja_expres",
    key: "queja_expres",
    width: 55,
    scopedSlots: { customRender: "queja_expres" },
  },
  {
    title: "Estado",
    dataIndex: "estado",
    key: "estado",
    width: 75,
    scopedSlots: { customRender: "estado" },
  },
  {
    title: "Fecha de Creación",
    dataIndex: "fecha_creacion",
    key: "fecha_creacion",
    width: 100,
    scopedSlots: { customRender: "fecha_creacion" },
  },
  {
    title: "Instancia de recepción",
    dataIndex: "nombre_instancia_recepcion",
    key: "nombre_instancia_recepcion",
    width: 100,
    scopedSlots: { customRender: "nombre_instancia_recepcion" },
  },
];

const columnsExcelBackup = [
  { label: "Código de la Queja", field: "codigo_queja" },
  { label: "Tipo de Identificación", field: "tipo_identificacion_CF" },
  { label: "Número de Identificación", field: "numero_id_CF" },
  { label: "Nombre", field: "nombres" },
  { label: "Entidad", field: "nombre_entidad" },
  { label: "Producto", field: "producto_nombre" },
  { label: "Express", field: "queja_expres" },
  {
    label: "Instancia de recepción  ",
    field: "nombre_instancia_recepcion",
  },
  { label: "Estado", field: "estado" },
  { label: "Fecha de Creación", field: "fecha_creacion" },
];

export default {
  components: {
    GeneralDashboard,
    ComplaintCardMini,
    ComplaintStatusLabel,
  },
  data() {
    return {
      //Complaints
      complaints: [],
      collapsed: false,
      columns: [],
      columnsExcel: [],
      complaintsInfo: [],
      page: 1,
      pageSize: 100,
      count: 0,
      actualQuery: {},
      json_data: [],
      json_csv: [],
      json_fields: [],
      loading: false,
      loadingReport: false,
      pageSizeDownload: 10000,
      filterSet: {},
      filtersToDownload: { columns: [], data: [] },
    };
  },
  watch: {
    page: function (newVal, oldVal) {
      if (newVal == this.$route.query.page) {
        return;
      } else {
        this.$router.replace({ query: { page: this.page } }).catch(() => {
          return;
        });
      }
    },
    "$route.query.page": function () {
      this.searchComplaints();
    },
  },
  created() {
    if (this.$route.query.page) {
      this.page = parseInt(this.$route.query.page);
    } else {
      this.page = 1;
    }
    // Se carga por defecto los valores para una semana
    this.searchComplaints({
      filter_model: {
        fecha_creacion: {
          filter_type: "date",
          operator: "AND",
          condition1: {
            date_from: moment()
              .set(new Date())
              .subtract(7, "d")
              .format("YYYY-MM-DD"),
            date_to: null,
            filter_type: "date",
            type: "greaterThanOrEqual",
          },
          condition2: {
            date_from: moment().set(new Date()).format("YYYY-MM-DD"),
            date_to: null,
            filter_type: "date",
            type: "lessThanOrEqual",
          },
        },
      },
    });
  },
  methods: {
    searchComplaints(ev = this.actualQuery) {
      this.generateFiltersSheet(ev.filterSet);
      this.loading = true;
      this.loadingReport = true;
      this.actualQuery = ev;
      //this.getDataToPrint();
      this.showToast("info", "Buscando resultados...");
      const promises = [];
      // Construcción de todas las peticiones para la vista de reporte general
      // 1. Petición de volumetría
      const bodyVolumetry = {
        filter_model: this.actualQuery?.filter_model,
      };
      promises.push(this.$api.getVolumetry(bodyVolumetry));
      // 2. Tabla de quejas
      const bodyComplaint = {
        filter_model: this.actualQuery?.filter_model,
        limit: this.pageSize,
        offset: this.page - 1,
      };
      promises.push(this.$api.searchComplaints(bodyComplaint));

      // Ejecutamos todas las peticiones al backend
      Promise.allSettled(promises)
        .then((response) => {
          // Volumetría
          // Volumetría
          const volumetry = response[0]?.value?.data;
          this.complaints = [];
          let factor = 1;
          arrVolumetry.forEach((item) => {
            factor = item.target.includes("secconds") ? 3600 : 1;

            if (volumetry[item.percent] === null) {
              this.complaints.push({
                title: item.name,
                percent: 0,
                numComplaints: 0,
                danger: false,
                fall: false,
              });
            } else {
              this.complaints.push({
                title: item.name,
                percent: +(
                  volumetry[item.percent] < 0
                    ? -1 * volumetry[item.percent]
                    : volumetry[item.percent]
                ).toFixed(2),
                numComplaints: volumetry[item.target] / factor,
                danger: volumetry[item.percent] < 0,
                fall: volumetry[item.percent] < 0,
              });
            }
          });
          // Se agregan las nuevas columnas
          let keys_columns = Object.keys(this.actualQuery?.filter_model);
          this.columns = [...columnsBackup];
          this.columnsExcel = [...columnsExcelBackup];
          if (keys_columns.length > 1) {
            this.$refs.my_filter_search.$data.columns.forEach(
              (el) => (this.filterSet[el.name] = el.description)
            );
            keys_columns.pop();
            keys_columns.forEach((el) => {
              // Tabla
              this.columns.push({
                title: this.filterSet[el],
                dataIndex: el,
                key: el,
                width: 100,
              });
              // Excel
              this.columnsExcel.push({ label: this.filterSet[el], field: el });
            });
          }
          // Quejas
          const complaint_search = response[1]?.value?.data;
          this.complaintsInfo = complaint_search?.results?.map((item, idx) => {
            return {
              ...item,
              idx,
              nombre_entidad: capitalize(item.nombre_entidad),
              nombres: capitalize(item.nombres),
              fecha_creacion: format(
                new Date(item.fecha_creacion),
                "dd/MM/yyyy hh:mm a"
              ),
            };
          });

          this.count = complaint_search?.count;
          this.loading = false;
          this.loadingReport = false;
        })
        .catch((error) => console.error(error));
    },
    getDataToPrint(ev = this.actualQuery) {
      this.generateFiltersSheet(ev.filters);
      this.actualQuery = ev;
      const bodyComplaint = {
        filter_model: this.actualQuery?.filter_model,
        offset: this.startDownload,
      };
      this.$api
        .searchComplaints(bodyComplaint)
        .then((response) => {
          const complaint_search = response?.data;
          this.json_data = complaint_search?.map((item) => {
            return {
              ...item,
              nombre_entidad: capitalize(item.nombre_entidad),
              nombres: capitalize(item.nombres),
              fecha_creacion: format(
                new Date(item.fecha_creacion),
                "dd/MM/yyyy hh:mm a"
              ),
            };
          });
        })
        .catch((error) => console.error(error));
    },
    onGetAllComplaints(isCsv = false) {
      // Se realiza una primera petición para determinar el total de registros a descargar

      this.loadingReport = true;

      let pages_all = Math.trunc(this.count / this.pageSizeDownload);
      let mod = this.count % this.pageSizeDownload > 0 ? 1 : 0;
      pages_all += mod;
      const promises = [];
      this.json_data = [];
      for (let i = 0; i < pages_all; i++) {
        const bodyComplaint = {
          filter_model: this.actualQuery?.filter_model,
          offset: i,
        };
        promises.push(this.$api.searchComplaints(bodyComplaint));
      }
      // Ejecutamos todas las peticiones.
      Promise.allSettled(promises)
        .then((response) => {
          let data = [];
          for (let i = 0; i < pages_all; i++) {
            const complaint_search = response[i]?.value?.data?.results;
            data = [...data, ...complaint_search];
          }

          this.loadingReport = false;
          if (isCsv) this.json_csv = data;
          else this.json_data = data;
        })
        .catch((error) => {
          this.loadingReport = false;
          this.showToast("error", error);
        });
    },

    generateFiltersSheet(filters) {
      if (!filters) return;
      let filterKeys = Object.keys(filters);
      let columnsData = [];
      let filterData = [];
      if (filterKeys.length > 0) {
        filterKeys.forEach((key) => {
          if (
            filters[key].type === "SELECT" ||
            filters[key].type === "SELECT-CHECK"
          ) {
            if (filters[key]?.data?.length > 0) {
              let column = {};
              filters[key].data.forEach((element) => {
                let obj = {};
                obj[key] = element;
                filterData.push(obj);
              });

              column.label = filters[key].description;
              column.field = key;
              columnsData.push(column);
            }
          } else if (filters[key].data) {
            let obj = {};
            let column = {};
            obj[key] = filters[key].data;
            filterData.push(obj);

            column.label = filters[key].description;
            column.field = key;
            columnsData.push(column);
          }
        });
      }
      this.filtersToDownload.columns = columnsData;
      this.filtersToDownload.data = filterData;
    },
    showToast(result = "info", msg) {
      this.$notification[result]({
        message: "Resultado",
        description: msg,
        onClick: () => {},
      });
    },
  },
};
</script>

<style scoped></style>
<style>
.search-complaint .ant-table-thead > tr > th {
  font-weight: bold;
  text-align: center;
  color: #6b7280;
}
</style>
